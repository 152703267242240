var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    [
      _c("ModalHeader", {
        attrs: { title: "Add or edit a photo" },
        on: {
          closeModal: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          !_vm.imageBase64
            ? [_c("UploadForm", { on: { upload: _vm.handleFormUpload } })]
            : [
                _c("Resize", {
                  attrs: { image: _vm.imageBase64 },
                  on: { crop: _vm.handleCrop },
                }),
              ],
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "action-buttons" },
        [
          _vm._l(_vm.actionButtons, function (button, key) {
            return [
              _c(
                "button",
                {
                  key: key,
                  staticClass: "action-button",
                  class: { disabled: button.disabled },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return button.action.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(button.text))]
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }