<template>
    <div>
        <SlabButton
            @click.native="showModal = true"
        >Upload</SlabButton>

        <UploadModal
            v-if="showModal"
            @close="showModal = false"
            @upload="handleUpload"
        />
    </div>
</template>

<script>
import SlabButton from './slab_button'
import UploadModal from './UploadModal'

export default {
    name: 'Upload',

    components: {
        SlabButton,
        UploadModal,
    },

    data() {
        return {
            showModal: false,
        }
    },

    methods: {
        handleUpload(payload) {
            this.showModal = false

            this.$emit('upload', payload)
        }
    },
}
</script>

