<template>
    <Modal>
        <ModalHeader
            title="Add or edit a photo"
            @closeModal="$emit('close')"
        />

        <div class="wrapper">
            <template v-if="!imageBase64">
                <UploadForm
                    @upload="handleFormUpload"
                />
            </template>

            <template v-else>
                <Resize
                    :image="imageBase64"
                    @crop="handleCrop"
                />
            </template>
        </div>

        <div class="action-buttons">
            <template v-for="(button, key) in actionButtons">
                <button
                    :key="key"
                    :class="{disabled: button.disabled}"
                    class="action-button"
                    @click.stop.prevent="button.action"
                >{{ button.text }}</button>
            </template>
        </div>
    </Modal>
</template>

<script>
import ModalHeader from './editing_modal_header_simple'
import Modal from './editing_modal'
import UploadForm from './UploadForm'
import Resize from './Resize'

export default {
    name: 'UploadModal',

    components: {
        ModalHeader,
        Modal,
        UploadForm,
        Resize,
    },

    data() {
        return {
            croppedImageBase64: false,
            imageBase64: false,
            fileName: false,
        }
    },

    computed: {
        actionButtons() {            
            return {
                cancel: {
                    text: 'Cancel',
                    action: () => this.$emit('close'),
                },
                done: {
                    text: 'Done',
                    disabled: !this.croppedImageBase64,
                    action: () => {
                        if (this.croppedImageBase64 && this.fileName) {
                            this.$emit('upload', {
                                imageBase64: this.croppedImageBase64,
                                fileName: this.fileName,
                                safeFileName: Math.floor(Math.random() * 100) + this.fileName.replace(/[^a-z0-9\.]/gi, ''),
                            })
                        }
                    },
                },
            }
        },
    },

    methods: {
        handleFormUpload(e, {fileName, base64}) {
            this.imageBase64 = base64
            this.fileName = fileName
        },

        handleCrop(data) {
            this.croppedImageBase64 = data
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/variables';
@import '~scss/mixins';

.wrapper {
    min-height: 400px;
    padding-top: 20px;
    padding-top: 20px;
}

.action-buttons {
    display: flex;
    border-top: 1px solid $border_light_grey;
}

.action-button {
    width: 50%;
    padding: 18px;
    border: none;
    outline: none;
    border-left: 1px solid $border_light_grey;

    &:first-of-type {
        border-left: none;
    }

    &.disabled {
        color: $border_light_grey;
        cursor: default;

        &:hover {
            background-color: transparent;
        }
    }
}
</style>

