var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "SlabButton",
        {
          nativeOn: {
            click: function ($event) {
              _vm.showModal = true
            },
          },
        },
        [_vm._v("Upload")]
      ),
      _vm.showModal
        ? _c("UploadModal", {
            on: {
              close: function ($event) {
                _vm.showModal = false
              },
              upload: _vm.handleUpload,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }