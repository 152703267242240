<template>
    <div class="wrapper">
        <div class="image-title">Resize</div>

        <div class="image">
            <VueCropper
                ref="cropper"
                :img="this.image"
                :autoCrop="true"
                :autoCropWidth="256"
                :autoCropHeight="215"
                :canScale="true"
                :fixed="true"
                :fixedNumber="[256, 215]"
                outputType="png"
                @realTime="handleRealTime"
            />
        </div>
    </div>
</template>

<script>
import { VueCropper } from 'vue-cropper'

export default {
    name: 'Resize',

    components: {
        VueCropper,
    },

    props: {
        image: { // Base64
            type: String,
            required: true,
        }
    },

    methods: {
        handleRealTime(data) {
            this.$refs.cropper.getCropData(data => {
                this.$emit('crop', data)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/variables';
@import '~scss/mixins';

.image-title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
}

.image {
    height: 300px;
}
</style>

