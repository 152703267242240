var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "image-title" }, [_vm._v("Resize")]),
    _c(
      "div",
      { staticClass: "image" },
      [
        _c("VueCropper", {
          ref: "cropper",
          attrs: {
            img: this.image,
            autoCrop: true,
            autoCropWidth: 256,
            autoCropHeight: 215,
            canScale: true,
            fixed: true,
            fixedNumber: [256, 215],
            outputType: "png",
          },
          on: { realTime: _vm.handleRealTime },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }